export const InternationalBusiness = {
    partner: [
      {
        "orgName": "AbitOs",
        "link": "https://us.accion.org/",
        "name":"Raimundo Lopez-Lima Levi,Rosangela De Morais",
        "email":"ray@abitos.com,rdemorais@abitos.com",
        "phone": "",
        "comments":"CDFI",
        "keywords":["Real Estate", "Income Tax", "Tax Analytics", "Foreign Ownership", "Process", "Documentation", "finance", "On-Line applications", "Packaging", "Private Pro Bono"]
      },
      {
        "orgName": "Accion",
        "link": "https://us.accion.org/",
        "name":"Fabiana Estrada",
        "email":"festrada@accioneast.com",
        "phone": "",
        "comments":"CDFI",
        "keywords":["Spanish", "Hispanic",  "Small Business", "Loan Packaging", "Micro Loans", "Entrepreneurs", "Access to Capital", "Bilingual"]
      },
      {
        "orgName": "Export-Import Bank of the United States",
        "link": "https://www.exim.gov/",
        "name":"Elena Mendez",
        "email":"elena.mendez@exim.gov",
        "phone": "",
        "comments":"",
        "keywords":["Trade Intelligence", "import-export", "multinational", "professional", "Funding Eligibility", "Immigration"]
      },
      {
        "orgName": "Prospera",
        "link": "https://prosperausa.org/",
        "name": "Myrna Sonora",
        "email": "msonora@prosperausa.org",
        "phone": "",
        "comments":"",
        "keywords":["Foreign", "Immigration", "Funding Eligibility", "Trade", "Hispanic", "Spanish", "Bilingual", "Expand", "Small Business", "Entrepreneur", "Training", "Consulting", "Grants", "Access to Capital"]
      },
      {
        "orgName": "Sant-La",
        "link": "https://www.santla.org/",
        "name": "Gepsie M. Metellus, Alphonse Piard:   ",
        "email": "gepsiem@santla.org; alphonse@santla.org",
        "phone": "",
        "comments":"Free Tax Preparation for eligible self-employed and small business owners",
        "keywords":["Immigration", "Haitian", "Creole", "Bilingual", "Free Tax Preparation"]
      },
    ],
  }
  
  export default InternationalBusiness
  